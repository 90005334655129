import "@/styles/globals.css";
import { Open_Sans } from "@next/font/google";
import { Analytics } from "@vercel/analytics/react";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";

const openSans = Open_Sans({
    subsets: ["latin"],
    variable: "--font-opensans",
});

const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
    return (
        <SessionProvider session={session}>
            <main className={`${openSans.variable} font-sans`}>
                <Component {...pageProps} />

                {/* Root element for modals */}
                <div id="modal-root" />
            </main>

            <Analytics />
        </SessionProvider>
    );
};
export default App;
